.checkout-mercado-pago {
    width: 100%;
    &__caontainer {
        .form-item {
            margin: 20px;

            label {
                color: rgba(0, 0, 0, 0.85);
                font-weight: 500;
                font-size: 16px;
                font-feature-settings: 'tnum', 'tnum';
            }

            input,
            select {
                margin-left: 20px;
                width: 250px;
                border: 1px solid #bfbfbf;
                height: 32px;
                padding: 0 11px;
                text-align: left;
                background-color: transparent;
                border-radius: 2px;
                outline: 0;

                &:focus {
                    border-color: #40a9ff;
                    box-shadow: 0 0 0 2px rgba(143, 194, 241, 0.5);
                }
            }

            .date-card {
                width: 60px;
            }

            .date-separator {
                margin-left: 17px;
            }

            .input-error {
                border-color: #ff7875;
                box-shadow: 0 0 0 2px rgba(241, 151, 143, 0.5);
            }
        }

        .btn-mercado-pago {
            width: 100%;
            background-color: #40a9ff;
        }
    }
}
.form-conekta {
    .form-row {
        margin: 15px 0px;
    }
    .number-card {
        width: 200px;
    }

    .cvv-input {
        width: 70px;
    }

    .cvv-label {
        margin-right: 13px;
    }

    .month-input {
        width: 65px;
    }

    .year-input {
        width: 80px;
    }

    .error-card {
        border-color: #d9363e;
        border-right-width: 1px !important;
        &:focus {
            border-color: #d9363e;
        }
    }
}
.conekta.text-center {
    width: 100%;
}
