.connected-runner {
    .company-status {
        width: 100%;

        .toggle-container {
            display: flex;
            justify-content: flex-end;

            .ant-switch-checked.ant-switch {
                background-color: #52c41a !important;
            }

            .ant-switch {
                background-color: #d9363e;
            }
        }
    }
}
