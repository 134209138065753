.margin-check {
  margin-bottom: 5px;
  margin-top: 5px;
}
.border-layout-ing {
  border: 1px solid #ffffff;
  border-bottom: 1px solid #d3d3e3;
  background-color: #ffffff;
  padding: 2px;
}
.space-content {
  justify-content: space-between;
}

.add-ingredient {
  padding-left: 10px;
}
.add-ingredient-button {
  background-color: #52c41a;
  border-color: #52c41a;
  padding-left: 10px;
}

.display-button-right {
  margin-left: auto;
  display: table;
}
