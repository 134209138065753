@import '../../../../scss/index.scss';

.packages {
    // min-height: 100vh;
    // height: 100vh;
    padding-left: 20px;
    padding-right: 20px;

    .zoom {
        &:hover {
            transform: scale(1.1);
        }
    }
    &__container {
        margin-top: 60px !important;

        &-basic {
            display: flex;
            justify-content: flex-end;
        }

        &-plus {
            display: flex;
            justify-content: center;
        }
        &-premium {
            display: flex;
            justify-content: flex-start;
        }
    }
    &__individual {
        background-color: white;
        border-radius: 22px;
        box-shadow: $regular-shadow;
        width: 350px;
        &-top {
            // background: beige;
            border-top-right-radius: 22px;
            border-top-left-radius: 22px;
            padding-top: 20px;
            padding-bottom: 10px;

            h3.ant-typography.name {
                color: white;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }

        &-price {
            padding-top: 20px;
            .container-price {
                font-weight: 300;
                font-size: 20px;

                span.ant-typography.price {
                    font-size: 40px;
                    font-weight: 700;
                    margin-left: 15px;
                    margin-right: 5px;
                }
            }
        }
        li.ant-list-item {
            padding: 6px;
        }
        ul.ant-list-items {
            padding: 0px 20px;
        }
        &-button {
            padding-bottom: 20px;

            .package-button {
                box-shadow: $regular-shadow;
                width: 250px;
                font-size: 20px;
                padding-top: 10px;
                padding-bottom: 10px;
                height: auto;
                border-radius: 50px;
                color: $font-light;

                &:hover,
                &:focus {
                    border-color: transparent;
                    color: white;
                }
            }
        }
    }

    .popular-individual {
        &-top {
            margin-top: -50px;
            background: $controlb-light-violet;
            border-top-right-radius: 22px;
            border-top-left-radius: 22px;
            color: $font-light;
            padding-top: 20px;
            padding: 20px;
            text-align: center;
            width: 350px;
        }

        &-bottom {
            background: $font-light;
            border-bottom-right-radius: 22px;
            border-bottom-left-radius: 22px;
            color: $font-light;
            padding-bottom: 30px;
            padding: 20px;
            text-align: center;
            width: 350px;
        }
    }

    .no-radius {
        border-radius: 0px !important;
    }

    .bg-color-rose {
        background-color: $controlb-rose;
    }
    .bg-color-light-violet {
        background-color: $controlb-light-violet;
    }
    .bg-color-violet {
        background-color: $controlb-violet;
    }

    @media only screen and (max-width: $media-breackpoint-up-sm) {
        height: auto;
        padding-left: 0;
        padding-right: 0;

        .packages__container {
            margin-top: 10px !important;
        }
        &__container {
            &-basic {
                display: block;
            }

            &-plus {
                display: block;
            }
            &-premium {
                display: block;
            }
        }

        .packages__individual {
            width: auto;
            box-shadow: none;
            // width: 310px;
        }

        .popular-individual-top,
        .popular-individual-bottom {
            width: auto !important;
            // width: 310px !important;
        }

        .popular-individual-top {
            margin-top: 0px;
        }
        .zoom {
            &:hover {
                transform: scale(1);
            }
        }
    }
}
