@import '../../../scss/index.scss';

.footer-content{
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background: #f0f2f5;
    background-color: $background-red;
    padding: 8.5px 50px;
    flex: 0 0 auto;
    box-shadow: 0px 0px 5px rgba(0,0,0,.6);
    // border-top-left-radius: 42px;
    // border-top-right-radius: 42px;

    p{
        color: white;
        font-size: 18px;
        margin-bottom: 0px;
    }

    &__center{
        text-align: center;
        p{
            color: white;
            font-size: 18px;
        }
    }

    &__right{
        text-align: right;
        p{
            color: white;
            font-size: 18px;
        }
        
    }
    .active{
        color: white;
        box-shadow: 0px 0px 5px rgba(247, 243, 243, 0.6);
        text-shadow: white 2px 5px;

    }
    

    button:hover{
        border-color: white;
        color: white;
        
    }
    
}

.menu-footer-btn{
    padding: 0;   
   }