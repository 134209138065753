.runner-active-order {
    &__back {
        text-align: right;
    }

    .card-step {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        // box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
        border-radius: 1.25rem;
    }
}
.mark-price {
    font-size: 16px;
    font-weight: bold;
}
