@import '../../../../scss/index.scss';

.my-subscription {
    &__top {
        .btn-state-active {
            background-color: transparent;
            border-color: $background-green;
            color: $background-green;
            text-transform: uppercase;
            box-shadow: $small-shadow;
        }
        .btn-state-inactive {
            background-color: transparent;
            border-color: $background-red-antd;
            color: $background-red-antd;
            text-transform: uppercase;
            box-shadow: $small-shadow;
        }

        .btn-change-package {
            background: rgb(144, 62, 117);
            background: linear-gradient(
                90deg,
                rgba(144, 62, 117, 1) 0%,
                rgba(185, 121, 167, 1) 0%,
                rgba(235, 73, 71, 1) 95%
            );
            // border-color: $controlb-violet;
            color: white;
            box-shadow: $small-shadow;
            &:focus {
                background-color: lighten(
                    $color: $controlb-light-violet,
                    $amount: 10
                );
                border-color: lighten(
                    $color: $controlb-light-violet,
                    $amount: 10
                );
            }

            &:hover {
                background-color: lighten(
                    $color: $controlb-light-violet,
                    $amount: 10
                );
                border-color: lighten(
                    $color: $controlb-light-violet,
                    $amount: 10
                );
            }
        }
    }
}
