.client-info {
    &__header {
        display: flex;
        justify-content: center;

        &-circle {
            width: 150px;
            height: 150px;
            background-color: aliceblue;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            .icon {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: rgb(2, 0, 36);
                background: linear-gradient(
                    90deg,
                    rgba(2, 0, 36, 1) 0%,
                    rgba(9, 9, 121, 1) 0%,
                    rgba(0, 212, 255, 1) 95%
                );
                font-size: 23px;
                color: white;
                font-weight: bolder;
            }
        }
    }

    &__container {
        text-align: center;
        padding: 20px;

        h3,
        h4 {
            margin-bottom: 0;
            color: #096dd9;
            .anticon {
                margin-right: 5px;
            }
        }

        .name {
            font-size: 18px;
            font-weight: 300;
        }
    }
}
