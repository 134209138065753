@import '../../../scss/index.scss';

.perfil-form {
    // margin-top: 15px;
    .hide {
        display: none;
    }

    &__container-image {
        text-align: center;
    }
    &__container-copy {
        text-align: center;
        margin: 10px 0;
    }
    input.ant-input,
    input.ant-input-number-input {
        border-radius: 10px;
    }
    .ant-form-item-label {
        padding-bottom: 0px;
    }
    .ant-row.ant-form-item {
        margin-bottom: 10px;
    }

    .ant-checkbox-wrapper.ant-checkbox-group-item {
        width: 100%;
    }

    &__services {
        margin-bottom: 0px !important;
        height: 25px;
    }
    &__divider {
        margin-bottom: 0px !important;
    }
    &__deliveryCost,
    &__button {
        margin-top: 10px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        width: 90%;
        margin: 20px;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;

        .perfil-form__deliveryCost {
            margin-top: 30px;
        }
        .perfil-form__container-button {
            width: 600px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.margin-button-perfil {
    margin-top: 25px;
}
.error-payload {
    color: red;
    margin-top: 10px;
    font-weight: 600;
}
