.type-order {
    padding: 10px 20px;
}

.cener-content {
    justify-content: center;
    flex-flow: inherit;
}

.align-icon {
    margin-top: -10px;
}
.text-style {
    font-weight: bold;
    margin-right: 5px;
    color: #1890ff;
    font-size: initial;
    text-transform: capitalize;
}
