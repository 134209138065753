.btn-install-login{
    position: absolute;
    margin: auto;
    right: 0;
    width: 100px;
    top: 30px;
}
.btn-install-update{
    // position: absolute;
    left: 0;
    right: 0;
    margin: auto;

}