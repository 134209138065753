.card-menu-search {
    .ant-card-body {
        padding: 16px;
    }

    &__title::first-letter {
        text-transform: uppercase;
    }

    &__description {
        overflow-wrap: break-word;
        margin-bottom: 4px;
    }

    &__noAvailable {
        color: red;
        text-transform: uppercase;
        font-weight: 600;
    }

    .anticon.color-red {
        color: red !important;
    }
}

.menu-search {
    padding: 0px 20px 0px 20px;

    .anticon {
        color: rgba(0, 0, 0, 0.25);
    }

    .ant-list-items {
        overflow-y: scroll;
        height: calc(100vh - 165px);
    }

    .card-dish {
        margin: 10px 12px;

        &__description {
            overflow-wrap: break-word;
            margin-bottom: 4px;
        }

        &__noAvailable {
            color: red;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    .banner-discount {
        background-color: lighten($color: red, $amount: 20);
        color: white;
        margin-bottom: 5px;
        border-radius: 10px;
        padding-left: 15px;

        .anticon-tag {
            margin-right: 6px;
        }

        &.item-free {
            background-color: lighten($color: #52c41a, $amount: 5);
            width: 86px;
        }
    }
    .ant-card-body {
        padding: 12px;
    }

    .ant-spin-spinning {
        display: flex;
        justify-content: center;
        height: 50vh;
        align-items: center;
    }
}

.search-active-scroll {
    height: calc(100vh - 200px);
    overflow-y: scroll;
}

.menu-costumer-button {
    padding: 10px 20px 10px 20px;
    // position: absolute;
    width: 100%;
    bottom: 12px;
    z-index: 5;

    .ant-btn-dangerous.ant-btn-primary {
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        border-radius: 7px;
    }

    &__left {
        .counter-square {
            width: 40px;
            border-color: white;
            background-color: transparent;
            color: white;
            border: 1px;
            border-style: solid;
        }
    }
}
form.form-search-menu {
    margin-top: 20px;
    padding: 0 20px;
    margin-bottom: 10px;
}
