.payment-form {
    width: 100%;
    margin: 10px 0px;
}

.card-payment {
    .ant-card-body {
        padding: 10px;
    }
}
