@import '../../../scss/index.scss';
.establishment-account {
    &__container {
        margin: 20px 10px;
        background: white;
        padding: 10px;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

        .tab-info {
            text-align: center;

            .subtitle {
                font-size: 18px;
                font-weight: 300;

                span.anticon {
                    margin-right: 5px;
                }
            }
        }
    }
}
