.first-section-report {
    margin: 20px 10px;

    &__left {
        .text-dates {
            font-size: 16px;
        }
    }

    &__right {
        text-align: right;
    }
}

.footer-section-report {
    margin: 15px 0px;
}
.comment-notseen {
    background-color: #52c41a;
    border-color: #52c41a;
}
.comment-seen {
    background-color: #1890ff;
    border-color: #1890ff;
}
