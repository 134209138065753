.table-promo {
    &__top {
        display: flex;
        justify-content: flex-end;
    }
}

.container-image {
    display: flex;
    justify-content: center;
}
