.list-restaurant {
    &.scoll {
        overflow-y: scroll;
        height: 41vh;
    }

    &.scroll.small-cell {
        height: 39vh;
        overflow-y: scroll;
    }

    &.scroll.large-cell {
        height: 51vh;
        overflow-y: scroll;
    }

    .ant-list-item {
        padding: 5px 0;
    }

    .card-restaurant {
        width: 100%;
        border-radius: 14px;

        &__image-container {
            display: flex;
            align-items: center;
        }

        &__info {
            padding-left: 10px;
            h4 {
                text-transform: capitalize;
            }
        }

        .ant-card-body {
            padding: 10px;
        }
    }
}
