.dish-form{
    &__container-image{
        text-align: center;
        margin-bottom: 20px;
    }

    .ant-row{
        margin-bottom: 10px;
    }

    .ant-spin-nested-loading > div > .ant-spin{
        top: 30%;
    }
}