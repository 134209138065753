@import '../../scss/index.scss';

.runner {
    background-color: $background-light;
    min-height: 100vh;
}
.form-edit {
    margin: 30px;
}
.perfil-form__container-button {
    width: 300px;
    left: 0;
    right: 0;
    margin: auto;
}
.div-ganancias {
    display: flex;
    justify-content: center;
    background-color: #1890ff;
    width: 111px;
    margin: 0 auto;
    border-radius: 50px;
}
.text-ganancias {
    color: white;
    margin-top: 7px;
}
.div-ganancias-text {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.form-edit-documents {
    padding: 0 30px;
}
.container-alert {
    padding: 5px 30px 30px 30px;
    display: flex;
    justify-content: center;

    .ant-alert {
        width: 300px;
    }
}
