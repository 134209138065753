.button-Online {
    margin-right: 20px;
    background-color: #52c41a;
    border-color: #52c41a;

    &:focus,
    &:hover {
        background-color: #52c41a;
        border-color: #52c41a;
    }
}
.button-Offline {
    margin-right: 20px;
}
.button-documents {
    background-color: #52c41a;
    border-color: #52c41a;
}
.button-documents:hover,
.button-documents:focus {
    background: #87cf63;
    border-color: #87cf63;
}
