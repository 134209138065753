.order-print{
    padding: 16px;
    margin-top: 40px;
    width: 250px;

    &__top, &__title{
        text-align: center;
        // margin-bottom: 10px;

    }

    &__container{
        margin-bottom: 10px;
    }
    &__container-right{
        text-align: right;
        p{
            margin-bottom: 0;
        }
        .last{
            margin-bottom: 10px;
        }
    }

    .divider{
        border-top: 2px solid;
    }
    .divider-dashed{
        border-top: 1px dashed;
        margin-top: 0; 
        margin-bottom: 10px; 
    }
    .ant-divider.ant-divider-horizontal.divider{
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .margin-items{
        margin-bottom: 6px;
    }
}

.mb-0{
    margin-bottom: 0;
}

.display-none{
    display: none
}