@import '../../../scss/index.scss';

.menu-sider {
    // padding: 30px;
    text-align: center;

    &__image {
        width: 100px;
        height: 100px;

        .ant-image-img {
            border-radius: 50%;
        }
    }
    h1 {
        color: $font-light;
        text-align: center;
    }
}

.menu-side-btn {
    color: $font-grey-ant;
    padding: 0;
}
.margin-bottom-label {
    margin-bottom: 0px;
}
