@import '../scss/variables';

.ant-layout-sider-zero-width-trigger{
    top: 12px;
    font-size: 30px;
    background-color: $background-grey-dark;
    z-index: 1000;

}

.layout-admin{
    height: 100vh;
}