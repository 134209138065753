@import '../../../scss/index.scss';
.home {
    height: calc(100vh - 85px);

    &__container {
        padding: 5px 20px;
        overflow-y: scroll;

        .btn-search-restaurant {
            border-radius: 25px;
            border: 1px solid #d9d9d9;
            background-color: #d9d9d9;
            color: $border-grey-dark;
        }

        &-search {
            padding-bottom: 10px;
        }
    }
}
