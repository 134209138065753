@import './../../../../../scss/index.scss';

.summary {
    border-right: 1px solid $background-grey-light;
    padding: 0px 20px;

    &__title {
        font-size: 22px;
        font-weight: 400;
    }
    .text-important {
        color: $controlb-light-violet;
    }

    &__icon {
        font-size: 20px;
        color: $controlb-light-violet;
    }

    @media only screen and (max-width: $media-breackpoint-up-sm) {
        border: none;
        padding: 0;
        .ant-divider-inner-text {
            padding: 0;
        }
    }
}
