.list-active-orders {
  .card {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background-color: #fff;
    border-radius: 0.25rem;
    width: 100%;
    margin: 22px 0px;
    cursor: pointer;

    .ant-card-body {
      padding: 20px;
    }

    .avatar-company {
      background-color: #bfbfbf;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      position: absolute;
      top: -49px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        height: 70px;
        width: 70px;
        border-radius: 50%;
      }
    }

    .order-number {
      color: white;
      margin-bottom: 0;
      border-radius: 20px;
    }

    h5 {
      margin-top: 8px;
    }

    span.anticon.anticon-right.right {
      position: absolute;
      right: 0;
      font-size: 16px;
    }
  }
  &__container-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
