.update{
    min-height: 100vh;

    &__container{
        padding: 5px 20px 20px 20px;
        &-title{
            text-align: center;
            margin-top: 20px;

            h3{
                text-transform: capitalize;
            }
        }
    }
}