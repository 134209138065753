.report{
    min-height: 100vh;



    &__conten{
        padding: 5px 20px;
        &-title{
            text-align: center;
            margin-top: 20px;

            h3{
                text-transform: capitalize;
            }
        }
    }

    &-tabs{
        margin: 20px;

        &__title{
            font-size: 22px;
        }
    }
}