@import '../../../scss/index.scss';

.subscription {
    min-height: 100vh;

    &__container {
        padding: 5px 20px;

        .ant-tabs-ink-bar {
            position: absolute;
            background: $controlb-violet;
            pointer-events: none;
        }
    }
}
