$defaultFontSize: 16px;

$primary-color: #0098d3;
$primary-color-hover: #0280b3;

$menu-color: #252527;

$font-light: #fff;
$font-grey-light: #e8e8e8;
$font-grey: #808080;
$font-grey-dark: #000;
$font-dark: #000;
$font-grey-ant: rgba(255, 255, 255, 0.65);

$border-light: #fff;
$border-grey-light: #e8e8e8;
$border-grey: #808080;
$border-grey-dark: #595959;
$border-dark: #252527;
$border-green: #52c41a;

$background-light: #fff;
$background-grey-white: #f3f2f2;
$background-grey-light: #e8e8e8;
$background-grey: #808080;
$background-grey-dark: #595959;
$background-dark: #000;
$background-succes: #84b84c1f;
$background-error: #ff00001f;
$background-green: #52c41a;
$background-red: #c53535;
$background-red-antd: #ff4d4f;

$success: #84b84c;
$error: #f00;
$controlb-red: #eb4947;
$controlb-rose: #f3a2a3;
$controlb-light-violet: #b979a7;
$controlb-violet: #903e75;

$small-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
$regular-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
$larger-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);

// Responsive down size
$media-breackpoint-down-xs: 413.98px;
$media-breackpoint-down-sm: 573.98px;
$media-breackpoint-down-md: 767.98px;
$media-breackpoint-down-lg: 991.98px;
$media-breackpoint-down-xl: 1199.98px;

// Responsive up size
$media-breackpoint-up-xs: 320px;
$media-breackpoint-up-sm: 576px;
$media-breackpoint-up-md: 768px;
$media-breackpoint-up-lg: 992px;
$media-breackpoint-up-xl: 1200px;
