.document-modal {
    &__no-files {
        display: flex;
        justify-content: center;

        .no-files-text {
            font-size: 18px;
            font-weight: 300;
        }
    }
    &__container {
        display: flex;
        justify-content: center;
        .approve-button {
            background-color: #52c41a;
            border-color: #52c41a;
            color: white;

            &:focus,
            &:hover {
                background-color: darken($color: #52c41a, $amount: 5);
                border-color: darken($color: #52c41a, $amount: 5);
                color: white;
            }
        }
    }
}
