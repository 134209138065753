.margin-check {
  margin-top: 25px;
}
.border-layout {
  border: 1px solid #d3d3e3;
  background-color: #ffffff;
  padding: 13px;
}
.space-content {
  justify-content: space-between;
}
.margin-form {
  margin: 20px;
}
.margin-left {
  margin-left: 5px;
}
