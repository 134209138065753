// .image-advice {
//     .image-item {
//         max-width: 100vh;
//     }
// }
// @media only screen and (min-width: 768px) {
//     .image-advice {
//         .image-item {
//             width: 250px;
//             height: 250px;
//         }
//     }
// }

// .ant-modal-body {
//     .modal-class {
//         padding: 0px !important;
//     }
// }

.image-item {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.center-element {
    margin: 0 auto;
    display: block;
}
.margin-pharagraf {
    margin-top: 10px;
    margin-bottom: 20px;
    text-transform: capitalize;
    text-align: center;
}
