.description-order {
    h1.label-error {
        color: red;
    }
    // .ant-select:not(.select-nueva) .ant-select-selector {
    //     background-color: #ffcfcf;
    // }
    // .ant-select:not(.select-aprobada) .ant-select-selector {
    //     background-color: #cff6ff;
    // }
    // .ant-select:not(.select-lista) .ant-select-selector {
    //     background-color: #fffcd9;
    // }
    // .ant-select:not(.select-entregada) .ant-select-selector {
    //     background-color: #ffe7cf;
    // }
    // .ant-select:not(.select-pagada) .ant-select-selector {
    //     background-color: #cfffd2;
    // }
}
.mb-10 {
    margin-bottom: 10px;
}
.align-items {
    align-items: baseline;
}
.margin-left {
    margin-left: 8px;
}
.opcion-nueva {
    background-color: #ffcfcf !important;
}
.opcion-aprobada {
    background-color: #cff6ff !important;
}
.opcion-lista {
    background-color: #fffcd9 !important;
}
.opcion-entregada {
    background-color: #ffe7cf !important;
}
.opcion-pagada {
    background-color: #cfffd2 !important;
}
.nueva-select {
    .ant-select:not(.select-color) .ant-select-selector {
        background-color: #ffcfcf;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: #919090;
    }
}
.aprobada-select {
    .ant-select:not(.select-color) .ant-select-selector {
        background-color: #cff6ff;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: #919090;
    }
}
.lista-select {
    .ant-select:not(.select-color) .ant-select-selector {
        background-color: #fffcd9;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: #919090;
    }
}
.entregada-select {
    .ant-select:not(.select-color) .ant-select-selector {
        background-color: #ffe7cf;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: #919090;
    }
}
.pagada-select {
    .ant-select:not(.select-color) .ant-select-selector {
        background-color: #cfffd2;
    }
    .ant-select-single.ant-select-open .ant-select-selection-item {
        color: #919090;
    }
}
