@import '../../../scss/index.scss';

.description-item {
    &__container-image {
        text-align: center;
        margin-top: 15px;
        // img {
        //     width: 310px;
        //     margin-top: 14px;
        //     margin-bottom: 20px;
        //     min-width: 310px;
        //     min-height: 210px;
        // }
    }

    &__container {
        h3,
        h5,
        .counter {
            text-align: center;
        }

        .right {
            text-align: right;
        }
    }
    .banner-discount {
        background-color: lighten($color: red, $amount: 20);
        color: white;
        margin-bottom: 5px;
        border-radius: 10px;
        padding-left: 15px;
        width: 165px;

        .anticon-tag {
            margin-right: 6px;
        }

        &.item-free {
            background-color: lighten($color: #52c41a, $amount: 5);
            width: 86px;
        }
    }
    .btn-red {
        background-color: $background-red;
    }

    .container-gray-ingredients {
        justify-content: space-between;
        background-color: #f3f3f3;
        padding: 10px;
        align-items: center;
    }
    .container-ingredients {
        justify-content: space-between;
    }
    .container-productName {
        justify-content: space-between;
        padding: 5px;
        float: left;
    }
    .container-price-check {
        justify-content: space-between;
        padding: 5px;
        float: right;
    }
    .margin-price {
        margin-top: -15px;
        font-weight: bold;
    }
    .align-input-baseline {
        align-items: baseline;
    }
    .justify-items-uoms {
        justify-content: space-between;
    }
    .margin-left {
        margin-left: 5px;
        align-items: baseline;
        margin-top: 10px;
    }
}
