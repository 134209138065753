@import '../../../scss/index.scss';

.search-restaurants {
    padding: 20px;

    .select-categories {
        margin-top: 20px;
        margin-bottom: 40px;
    }
}
