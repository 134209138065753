@import '../../../scss/index.scss';

.perfil{
    background-color: $background-light;
    min-height: 100vh;
    &__content{
        // margin-top: 68px;
        padding: 20px;


        h1{
            text-align: center;
            font-size: 1.4rem;
        }
    }

    .payment-account-container{
        width: 320px;
        left: 0;
        right: 0;
        margin: auto;
    }

    @media only screen and (min-width: 568px) {
        .payment-account-container{
            width: 360px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}