@import '../../../scss/index.scss';

.change-password{
    background-color: $background-light;
    min-height: 100vh;
    &__content{
        // margin-top: 68px;
        // padding: 20px;


        h1{
            text-align: center;
            font-size: 1.4rem;
        }
    }
}