@import '../../../scss/index.scss';

.credit-cards {
    background-color: $background-light;
    min-height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
    height: calc(100vh - 85px);
    overflow-y: scroll;

    &__container {
        padding: 20px;

        .add-card {
            .ant-col {
                text-align: center;
            }
            &__button {
                box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease 0s;
                font-weight: bold;
                margin-bottom: 20px;
            }
        }
    }
}
