@import '../../../scss/index.scss';

.login-form {
    padding: 0 22px;

    .ant-form-item-required {
        font-size: 16px;
        font-weight: 500;
    }

    .login-form-register {
        margin-right: 10px;
    }

    .ant-btn-round {
        width: 150px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        font-weight: bold;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    &__content {
        &-right {
            text-align: right;
        }
    }
}
