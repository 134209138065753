.menu-customer {
    // height: calc(100vh - 70px);
    // height: calc(100vh);
    &__title{
        background-color: white;
        text-align: center;
        padding-top: 20px;
    }
   
}
.content-client{
    background-color: #eff2f5;
}

