.promo-banner {
    margin: 0 20px;
    margin-bottom: 20px;

    .sortable-handler {
        // touch-action: none;
        touch-action: pan-y;
    }
}
.container-img img {
    width: 310px;
    min-width: 310px;
    min-height: 210px;
    text-align: center;
}
.container-coupon {
    text-align: center;
}
