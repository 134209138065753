@import '../../../scss/index.scss';

.credit-cards {
    background-color: $background-light;
    min-height: 100vh;
    &__container {
        padding: 20px;

        .add-card {
            .ant-col {
                text-align: center;
            }
            &__button {
                box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
                transition: all 0.3s ease 0s;
                font-weight: bold;
                margin-bottom: 20px;
            }
        }
    }
}
