@import '../../../../../scss/index.scss';
.payment-details {
    padding: 0 20px;
}

.approve-payment-btn {
    background-color: $background-green;
    border-color: $background-green;

    &:hover,
    &:focus,
    &:active {
        background-color: darken($color: $background-green, $amount: 5);
        border-color: darken($color: $background-green, $amount: 5);
    }
}
