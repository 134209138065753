@import '../../../scss/index.scss';

.disable-restaurant {
    background-color: $background-light;
    min-height: 100vh;

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
