@import '../../../scss/index.scss';

menu-admin {
    // .ant-list{
    //     // margin-top: 68px;
    // }

    &_header {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
            ),
            url('../../../assets/img/png/pandeElote.png');
        background-position: center;
        background-size: cover;

        h1 {
            color: $font-light;
            font-size: 20px;
        }
    }

    .ant-collapse-header {
        padding-bottom: 0;
    }

    .menu-collapse {
        span.anticon-caret-right.ant-collapse-arrow {
            margin-top: 10px;
            font-size: 22px;
        }

        &__sub {
            background-color: $background-grey-light;
            text-transform: uppercase;
        }
    }

    .menu__header-right {
        margin-top: 9px;

        h3 {
            margin-bottom: 0;
            letter-spacing: 1.4px;
            text-transform: capitalize;
        }
    }

    .dish-list {
        &__content {
            h4,
            h5 {
                text-align: center;
                font-size: 18px;
            }
        }
    }

    .dish-contents {
        text-align: center;
    }

    .dish-description {
        margin-top: 15px;
    }

    .dish-switch {
        &__left {
            p {
                margin-bottom: 4px;
            }
        }

        &__right {
            .ant-switch {
                // margin-left: 12px;
                margin-top: 5px;

                @media only screen and (min-width: 768px) {
                    margin-top: 0px !important;
                }
            }
            .switch-toGo {
                margin-top: 26px;

                @media only screen and (min-width: 768px) {
                    margin-top: 5px !important;
                }
            }
        }
    }

    .ant-list-item-meta-title {
        text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
    }

    @media only screen and (min-width: 550px) {
        .dish-description {
            display: flex;
            flex: 1 1;
            align-items: flex-start;
            max-width: 60%;
            margin-top: 15px;
        }
    }

    // @media only screen and (min-width: 768px) {

    //     .dish-description{
    //         display: flex;
    //         flex: 1 1;
    //         align-items: flex-start;
    //         max-width: 60%;
    //         margin-top: 15px;
    //     }
    // }
}

.ant-collapse-header {
    // background-image: linear-gradient( rgba(0, 0, 0,  0.5), rgba(0, 0, 0, 0.5) ),url('../../../assets/img/png/pandeElote.png');
    background-position: center;
    background-size: cover;
    color: $font-light;
}

.btn-icons {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.buttons__containers {
    text-align: right;

    .btn-add {
        background-color: $background-green;
        border-color: $border-green;
    }
}
.border-layout {
    border: 1px solid #ffffff;
    border-bottom: 1px solid #d3d3e3;
    background-color: #ffffff;
    padding: 13px;
}
.background-layout {
    background-color: #ffffff;
}
.space-content {
    justify-content: space-between;
}
.align-items-center {
    display: flex;
    align-items: center;
}
.height-items {
    line-height: 35px;
}
.align-items-menu {
    display: block;
    align-items: center;
}
.margin-button-ing {
    margin-left: 5px;
}
.btn-margin-add {
    margin-top: 12px;
    background-color: #52c41a;
    border-color: #52c41a;
}
.margin-button-add {
    margin-top: 0px;
    margin-left: 5px;
}
.button-delete-uom {
    margin-top: 24px;
    margin-right: 20px;
}
