@import '../../../scss/index.scss';

.orders {
    padding: 10px;
    li.ant-list-item{
        padding: 0 18px !important;
    }
    .ant-descriptions-header{
        margin-bottom: 5px !important;
    }
    
    span.ant-descriptions-item-label{
        font-weight: bold !important;
    }

    .label-bell{
        background-image: url('../../../assets/img/png/campana_Icon.png');
        background-size: cover;
        width: 40px;
        height: 42px;
        text-align: center;
        text-align-last: center;
        padding: 1px;
        font-size: 15px;
        margin-right: 10px;
        background-color: transparent;
        border-color: transparent;
        font-weight: bold;
    }
}