@import '../../../scss/index.scss';

.register-form {
    padding: 20px;
    // margin-top: 68px;

    .ant-row.ant-form-item {
        margin-bottom: 5px;
    }

    &__container {
        text-align: center;
    }

    .validate-password {
        margin: 10px 0;
        ul {
            list-style: none;
        }
    }

    .agreement {
        margin: 20px 0;
    }

    .ant-btn-dangerous.ant-btn-primary {
        margin-top: 20;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }
}
