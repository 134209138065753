@import './scss/index.scss';

html,
body {
    overscroll-behavior-y: contain;
}
.App {
}

.ant-input {
    font-size: 16px !important;
}
