@import '../../scss/index.scss';
.login {
    &__content {
        background-color: $background-light;
        align-items: center;
        min-height: 100vh;
        flex-direction: column;

        &-subtitle {
            padding: 0 22px;
            h1 {
                text-align: center;
                font-size: 22px;
                font-weight: bold;
            }
        }
    }

    &__content-banner {
        height: 250px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            url('../../assets/img/png/banner.png');
        background-repeat: no-repeat;
        min-height: 250px;
        background-size: cover;
        margin-bottom: 20px;
        background-position-y: bottom;

        h1 {
            position: absolute;
            top: 125px;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2rem;
            color: $font-light;
            text-transform: uppercase;
            letter-spacing: 6px;
        }
    }

    .ant-form-item-control-input-content {
        text-align: center;
    }
}

/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {...}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .login__content-subtitle {
        left: 0;
        width: 500px;
        right: 0;
        margin: auto;
    }
    .login__content-subtitle h1 {
        font-size: 32px;
    }
    .login .ant-spin-container {
        left: 0;
        width: 500px;
        right: 0;
        margin: auto;
        padding: 20px 0;
        // border: 1px solid red;
        box-shadow: 2px 2px 5px #999;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
