.image-carousel {
    .image-item {
        max-width: 100vh;
    }
}

@media only screen and (min-width: 768px) {
    .carousel .slide img {
        width: 60% !important;
    }
}
