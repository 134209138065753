.perfil {
    min-height: 100vh;

    &__container {
        padding: 20px 20px;
    }

    &__icon {
        text-align: right;
        position: absolute;
        right: 40px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 1.5;
        font-size: 18px;
    }
}
