.align-content-order {
    margin-top: 8px;
    margin-left: 20px;
}
.padding-panel-order {
    padding-top: 0px;
    padding-bottom: 6px;
}
.margin-button-order {
    margin-top: 10px;
}
.margin-button-left {
    margin-left: 10px;
}

.list-active-orders {
    &__container-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
}
