@import '../../scss/index.scss';

.register-card-stripe {
    min-height: 100vh;
    background-color: $background-light;

    &__content {
        padding: 10px 20px;
    }
}
