.menuCustomer {
    overflow-y: scroll;

    .ant-tabs-nav {
        background-color: white;
        padding-bottom: 10px;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    }
    .ant-tabs-nav-list {
        text-transform: capitalize;
        padding: 0px 10px;
    }
    // .ant-tabs-content.ant-tabs-content-top{
    //     // overflow-y: scroll;
    //     // min-height: 595px;
    //     // height: 590px;

    // }
    .card-dish {
        margin: 10px 12px;

        &__description {
            overflow-wrap: break-word;
            margin-bottom: 4px;
        }
        &__noAvailable {
            color: red;
            text-transform: uppercase;
            font-weight: 600;
        }

        .banner-discount {
            background-color: lighten($color: red, $amount: 20);
            color: white;
            margin-bottom: 5px;
            border-radius: 10px;
            padding-left: 15px;
            width: 165px;

            .anticon-tag {
                margin-right: 6px;
            }

            &.item-free {
                background-color: lighten($color: #52c41a, $amount: 5);
                width: 86px;
            }
        }
    }
    .ant-card-body {
        padding: 12px;
    }
}
.active-scroll {
    height: calc(100vh - 150px);
}

.menu-costumer-button {
    padding: 10px 20px 10px 20px;
    // position: absolute;
    width: 100%;
    bottom: 12px;
    z-index: 5;

    .ant-btn-dangerous.ant-btn-primary {
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        border-radius: 7px;
    }
    &__left {
        .counter-square {
            width: 40px;
            border-color: white;
            background-color: transparent;
            color: white;
            border: 1px;
            border-style: solid;
        }
    }
}
.modal-class {
    .ant-modal-body {
        padding: 0px 0px 24px 0px !important;
    }
    .ant-modal-header {
        text-transform: capitalize;
        text-align: center;
    }
}
