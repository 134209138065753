.top-selling{
    &__title{
        text-align: center;
    }
    &__container-chart{
        text-align: center;
        margin-top: 20px;
    }
    &__container-search{
        margin: 15px 10px;

        &-right{
            text-align: right;
        }
    }
}
